<template>
<div class="page-container">
  <div class="section">
    <div class="control-box control-box-width">
      <div class="left" style="width: 60%;">
        <el-button class="control-item" icon="el-icon-back" @click="goBack">
          返回
        </el-button>
        <el-date-picker
            class="item-component"
            v-model="query.startTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择开始日期"
            :picker-options="pickerOptionsStart"
            @change="startTimeChange"
        >
        </el-date-picker>
        <el-date-picker
            class="item-component ml10"
            v-model="query.endTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择结束日期"
            :picker-options="pickerOptionsEnd"
            @change="endTimeChange"
        >
        </el-date-picker>
        <el-select
          style="width: 250px"
          class="item-component-select ml10"
          v-model="equipment"
          clearable
          placeholder="请选择数采设备"
          filterable
        >
          <el-option
            v-for="item in tableNumber"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
      <div class="cb-right right-center">
        <el-button
            type="primary"
            @click="handleExport"
        >
          导出
        </el-button>
        <el-button
          type="primary"
          @click="handelQueryCheck"
        >
          查询
        </el-button>
      </div>
    </div>
    <div class="content" style="padding: 0 0 0 20px">
      <div class="table-box" >
        <el-table
            :data="maintainData"
            border
            style="width: 100%;"
        >
          <el-table-column
              prop="deviceName"
              width="150"
              label="设备名称"
              align="left"
          ></el-table-column>
          <el-table-column
              prop="startTime"
              label="告警开始时间"
              align="center"
          ></el-table-column>
          <el-table-column
              prop="endTime"
              label="告警结束时间"
              align="center"
          ></el-table-column>
          <el-table-column
              prop="type"
              label="告警类型"
              align="center"
          >
          </el-table-column>
          <el-table-column
              prop="rangeValue"
              label="范围值"
              width="160"
              align="center"
          >
          </el-table-column>
          <el-table-column
              prop="outRangeValue"
              label="超范围值"
              align="center"
              width="160"
          >
          </el-table-column>
          <el-table-column
              prop="person"
              label="处理人"
              align="center"
          ></el-table-column>
          <el-table-column
              prop="time"
              label="处理时间"
              align="center"
              width="150"
          >
          </el-table-column>
          <el-table-column
              prop="reason"
              label="原因"
              align="center"
          ></el-table-column>
          <el-table-column
              prop="result"
              label="解决方案"
              align="center"
          >
          </el-table-column>

        </el-table>
        <div class="pagination-box">
          <el-pagination
            v-show="totalCount>0"
            :current-page="query.page"
            :page-sizes="[10, 20, 30, 40]"
            :total="totalCount"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import API from "@/api";
export default {
name: "abnormalDetails",
  data() {
    return {
      maintainData: [],
      tableNumber: [],
      filter: {
        startTime: undefined,
        endTime: undefined,
        teamId: '',
        count: 10,
        page: 1,
        name: '',
        totalCount: 0
      },
      query: {
        startTime: undefined,
        endTime: undefined,
        count: 10,
        page: 1,
        teamId: '',
        // sheetType: this.selectedSheetType,
      },
      totalCount: 0,
      equipment: '',
      equipmentDown: '',
      startTime: undefined,
      endTime: undefined,
      pickerOptionsStart:{
        disabledDate: time => {
          if (this.query.endTime) {
            return (
                time.getTime() > new Date(this.query.endTime).getTime() || time.getTime() < new Date(this.query.endTime).getTime() - 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
      pickerOptionsEnd:{
        disabledDate: time => {
          if (this.query.startTime) {
            return (
                time.getTime() < new Date(this.query.startTime).getTime() - 8.64e7 || time.getTime() > new Date(this.query.startTime).getTime() + 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
    };
  },
  mounted() {

  },
  created() {
    this.query.startTime = this.$route.query.startTime
    this.query.endTime = this.$route.query.endTime
    this.query.teamId = this.$route.query.teamIds
    this.startTime = this.$route.query.startTime
    this.endTime = this.$route.query.endTime
    this.getList()
  },
  methods: {
  getList() {
    API.CompanyPreserveList({icollection: 1,type: -1,name: '', page: 1, count: 1000000000, teamId: this.$route.query.teamIds}).then((response) => {
      console.log(response)
      this.tableNumber = response.message.data.list;
      this.handelQuery()
      // this.totalCount = response.totalCount;
    });
  },
    handelQueryCheck() {
      if (!this.query.startTime || !this.query.endTime) {
        this.$message.warning('请选择开始时间或结束时间!')
        return
      }
      this.query.page = 1
      this.query.count = 10
      this.handelQuery()
    },
    handelQuery() {
      this.equipmentDown = this.equipment
      this.startTime = this.query.startTime
      this.endTime = this.query.endTime
      API.alarmDetailList({...this.query,deviceId: this.equipment, }).then(res => {
        console.log(res)
        this.maintainData = res.message.data.list
        this.totalCount = res.message.data.totalCount
      })
    },
    handleExport() {
      if (!this.query.startTime || !this.query.endTime) {
        this.$message.warning('请选择开始时间或结束时间!')
        return
      }
      require.ensure([], () => {
        const {export_json_to_excel} = require('@/views/ExcelLeadOut/Export2Excel'); // 注意这个Export2Excel路径
        const tHeader = [
          '设备名称',
          '告警开始时间',
          '告警结束时间',
          '告警类型',
          '范围值',
          '超范围值',
          '处理人',
          '处理时间',
          '原因',
          '解决方案',
        ]; // 表格标题
        const filterVal = [
          'deviceName',
          'startTime',
          'endTime',
          'type',
          'rangeValue',
          'outRangeValue',
          'person',
          'time',
          'reason',
          'result',
        ]; // 标题属性key值
        API.alarmDetailList({...this.query,startTime: this.startTime,endTime:this.endTime,deviceId: this.equipmentDown,count:100000,page: 1}).then(res => {
          const list = res.message.data.list; // 把要导出的数据tableData存到list
          const data = this.formatJson(filterVal, list);
          export_json_to_excel(tHeader, data, '润版液明细'); // 最后一个是表名字
        })

      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    handleSizeChange(val) {
      this.query.count = val;
      this.query.page = 1;
      this.handelQuery();
    },
    // 当前页数改变时会触发
    handleCurrentChange(val) {
      this.query.page = val;
      this.handelQuery();
    },
    startTimeChange(val){
      if(this.query.endTime && new Date(val).getTime() > new Date(this.query.endTime).getTime()){
        this.query.startTime = null
      }else{
        this.query.startTime = val
      }
    },
    endTimeChange(val){
      // 最长时间跨度100天
      if(this.query.startTime && new Date(val).getTime() - new Date(this.query.startTime).getTime() > 100 * 24 * 60 * 60 *1000){
        this.query.endTime = null
      }else{
        this.query.endTime = val
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  }
}
</script>

<style scoped lang="scss">
.pagination-box {
  margin-top: 20px;
}
.control-item {
  margin-right: 15px;
}
.ml10{
  margin-left: 2%;
}
</style>
